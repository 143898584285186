export const initAnalytics = () => {
  const script1 = document.createElement('script');
  script1.src = 'https://www.googletagmanager.com/gtag/js?id=G-2S1T3J274P';
  script1.async = true;
  document.body.appendChild(script1);

  // ------------
  // ------------

  const script2 = document.createElement('script');
  script2.textContent = `window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());
  
    gtag('config', 'G-2S1T3J274P');`;
  document.body.appendChild(script2);
};


