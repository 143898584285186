// ----------- import Packs
import { goTo } from '@morfos/routes';
import AsyncStorage from '@react-native-async-storage/async-storage';

// ----------- import Internals
import { TuserInfo } from '#types/TuserInfo';
import { setData } from '#ctData';

// ----------- set Function
export const setDataSuccess = async (dataSuccess: TuserInfo) => {
  if (dataSuccess.userDB.userID) {
    // ----------- set Local Storage
    // await AsyncStorage.setItem('userID', dataSuccess.userDB.userID);

    // ----------- set Data
    const dataAfterAsync = [
      { path: 'projectData.authUser', val: { ...dataSuccess } },
      { path: 'sections.auth.condAuth', val: true },
      { path: 'sections.auth.msg', val: '' },
    ];

    // ----------- change Data
    setData(dataAfterAsync);
  }
  // goTo('system');

  // ----------- No Return
};
