import * as font from '@expo-google-fonts/nunito';

export const setFont = (): { [key: string]: string } => {
  const oldFont: { [key: string]: any } = { ...font };

  const newFont = {};
  for (const key in oldFont) {
    const element = oldFont[key];

    if (typeof element === 'string') {
      // @ts-ignore
      newFont[key] = element;
    }
  }

  return newFont;
};
