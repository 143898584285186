import { signInWithPopup } from 'firebase/auth';
import { GoogleAuthProvider } from 'firebase/auth';

import { checkData } from '#utils';
import { auth } from '#firebase';

export const googleAuth = async () => {
  // ---------- set Popup Auth Permission
  const provider = new GoogleAuthProvider();

  provider.addScope('profile');
  provider.addScope('email');
  const result = await signInWithPopup(auth, provider);

  // ---------- set User Data
  const userData = result.user.providerData[0];

  // ---------- check Data
  if (!userData) return checkData('userData');

  // ---------- set Return
  return userData;
};
