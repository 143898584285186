// ----------- import Packs
import React from 'react';
import {
  Image,
  ImageStyle,
  Text,
  TextStyle,
  useWindowDimensions,
} from 'react-native';

// ----------- import Internals
import { AutoView } from 'src/config/theme/AutoView';

// ----------- import Assets
import src4 from '#images/figma.png';
import src5 from '#images/mobile1.png';
import { CallToAction } from './CallToAction';
import { sizeVideo, stlLogo, stlTitle } from '../styles';

// ----------- set Component
export function Figma_1() {
  const { width } = useWindowDimensions();
  const stlMobile1: ImageStyle = {
    resizeMode: 'contain',
    width: sizeVideo(width),
    height: sizeVideo(width),
  };

  // ----------- set Return
  return (
    <AutoView fill_width i_center_h i_space={100} pad_bottom={200}>
      <Title />

      <Image source={src5} style={stlMobile1} />

      <CallToAction sectionID="sss3" />
    </AutoView>
  );
}

function Title() {
  const { width } = useWindowDimensions();

  const stlTitle2: TextStyle = {
    ...stlTitle(width),
    paddingLeft: 0,
    textAlign: 'left',
  };

  return (
    <AutoView i_row i_center_2 i_space={40} fill_2>
      <Image source={src4} style={stlLogo(width)} />

      <Text style={stlTitle2}>
        Conecte seu Figma
        <Text style={{ fontWeight: '300' }}>{' e puxe seus projetos '}</Text>
        <Text>direto pro código.</Text>
      </Text>
    </AutoView>
  );
}
