// ----------- import Packs
import { DocumentSnapshot } from 'firebase/firestore';

// ----------- import Internals
import { TgetSections, TsectionDB } from 'src/sections/types';
import { setData } from '#ctData';

// ----------- set Local Types
type Tprops = DocumentSnapshot<TgetSections>;

// ----------- set Function
export const setDataSuccess = async (dataSuccess: Tprops) => {
  // ----------- set Data
  const arrSections: TsectionDB[] = [];
  const sectionsData = dataSuccess.data();

  // ----------- check Data
  if (!sectionsData) throw new Error('check Data - sectionsData not found!');

  // ----------- set Data
  const { homeSections } = sectionsData;

  // ----------- check Data
  if (!homeSections) throw new Error('check Data - homeSections not found!');
  for (const key in homeSections) {
    const item = homeSections[key];

    arrSections.push(item);
  }
  arrSections.sort(
    (a, b) => Number(a.sectionPosition) - Number(b.sectionPosition),
  );

  const change1 = { path: 'sections.list.condList', val: true };
  const change2 = {
    path: 'sections.list.arrSections',
    val: arrSections,
  };

  // ----------- change Data
  setData([change1, change2]);

  // ----------- No Return
};
