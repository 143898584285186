import { setData, TctData } from '#ctData';

export const setDataBeforeAsync = () => {
  // ----------- change Data
  setData([
    { path: 'sections.auth.condAuth', val: false },
    { path: 'sections.auth.msg', val: '' },
  ]);

  // ----------- No Return
};
