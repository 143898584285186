// ---------- import Packs
import React from 'react';
import { TouchableOpacity, useWindowDimensions } from 'react-native';

// ---------- import Internals
import { googleSignin } from 'src/sections/actions/googleSignin';
import { stlBtn, stlBtnLabel, stlCTATitle, stlParagraph } from '../../styles';
import { TextLabel } from 'src/config/theme/TextLabel';
import { AutoView } from 'src/config/theme/AutoView';
import { useData } from '#ctData';

// ---------- import Assets
import { SocialCall } from './SocialCall';
import { UseCondLoader } from '#common/_temp/useMorfos';

// ---------- set Component
export const Public = ({ sectionID }: { sectionID: string }) => {
  // ---------- set Data
  const { width } = useWindowDimensions();
  const arrSections = useData(ct => ct.sections.list.arrSections);

  // ---------- set Data
  const currSection = arrSections.find((i: any) => i.sectionID === sectionID);

  // ---------- check Data
  if (!currSection) throw new Error('check Data - currSection not found!');

  // ---------- set Functions
  // const btnSubmit = () => setItems();
  const btnGoogle = () => googleSignin();

  return (
    <AutoView fill_width i_center_2 i_space={60}>
      <AutoView fill_width i_center_2 i_space={30}>
        <TextLabel style={stlCTATitle(width)}>Quero Participar!</TextLabel>
        <TextLabel center style={[stlParagraph(width)]}>
          Faça o pré cadastro para receber novidades sobre funcionalidades,
          lançamento e como fazer parte do programa Exclusivo do Alfa Privado.
        </TextLabel>
      </AutoView>

      <AutoView fill_width i_center_2 i_space={20}>
        <TouchableOpacity style={stlBtn(width)} onPress={btnGoogle}>
          <TextLabel style={stlBtnLabel(width)} center>
            Google
          </TextLabel>
        </TouchableOpacity>

        <UseCondLoader data={'sections.auth.condAuth'} />

        {/* <NoGoogle sectionID={sectionID} /> */}
      </AutoView>

      <SocialCall currSection={currSection} sectionID={sectionID} />
    </AutoView>
  );
};
