import { TscRoute } from '@morfos/routes';

import { TsectionDB } from './types';
import { Sections } from './';

const route: TscRoute = {
  path: 'home',
  routeCode: 'sections',
  component: Sections,
};

type Tsections = {
  route: TscRoute;
  list: { arrSections: TsectionDB[]; condList: boolean };
  auth: { msg: string; condAuth: boolean };
  form: { iptsChanges: {} };
};

export const routes: Tsections = {
  route,

  list: { arrSections: [], condList: false },
  auth: { msg: '', condAuth: true },
  form: { iptsChanges: {} },
};
