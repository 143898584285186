// ----------- set Local Types
import { setStlArr } from './setStlArr';
import { Tsize } from './types';

// ----------- set export Component
export const setSize = (props: Tsize) => {
  // ----------- set Props
  const { fill_2, fill_width, fill_height, style } = props;
  const { width, height, parent_row, no_fill = true } = props;

  // ----------- set Fill 2 Ways
  const fill2 = {
    flex: 1,
    // alignSelf: 'stretch',
    width: '100%',
    height: '100%',
  };
  const condFill2 = fill_2 ?? {};

  // ----------- set Fill 2 Ways
  const fillH = { alignSelf: 'stretch', width: '100%' };
  const fillV = { flex: 1 };

  const condInvert = (cond: boolean | undefined) => (cond ? fillH : fillV);

  const condFillH = fill_width ? condInvert(!parent_row) : {};
  const condFillV = fill_height ? condInvert(parent_row) : {};

  const condWidth = width ? { width } : {};
  const condHeight = height ? { height } : {};

  // ----------- set valTheme
  const objFill = { ...condFill2, ...condFillH, ...condFillV };
  const checkObjFill = Object.keys(objFill).length > 0;
  const condStl = setStlArr(style);
  const constAnyHeight = height || condStl?.height;

  const condNoFill = checkObjFill || (no_fill && !fill_2) || constAnyHeight;
  const condInitFill = condNoFill ? {} : fill2;

  // ----------- set valTheme
  const valTheme = { ...objFill, ...condInitFill, ...condWidth, ...condHeight };

  // ----------- set return
  return valTheme;
};
