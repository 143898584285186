// ---------- set Packs
import { setDoc, Timestamp, DocumentReference } from 'firebase/firestore';

// ---------- set Internals
import { TuserAuthData, TuserDB, TuserInfo } from '#types/TuserInfo';

// ---------- set Local Types
type Tprops = {
  userAuthData: TuserAuthData;
  refDoc: DocumentReference<TuserInfo>;
};

// ---------- export Function
export const addNewUser = async (props: Tprops) => {
  // ---------- set Props
  const { userAuthData, refDoc } = props;

  // ---------- set Doc Data to Add
  const userDB: TuserDB = {
    userID: refDoc.id,
    userCreatedAt: Timestamp.now(),
  };

  // ---------- set New Doc
  const dataSuccess = { userAuthData, userDB };
  await setDoc(refDoc, dataSuccess);

  return dataSuccess;
};
