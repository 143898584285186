// ----------- import Packs
import { arrayUnion, collection, doc, updateDoc } from 'firebase/firestore';
import { firestoreInit } from '#firebase';

// ----------- import Internals
import { Tfield } from 'src/sections/types';
import { getCtData } from '#ctData';

// ---------- set Local Type

export const asyncFb1 = async (currSection: Tfield, priceToPay: number) => {
  // ---------- set Data
  const userID = getCtData(ct => ct.projectData?.authUser?.userDB?.userID);

  // ---------- set Async Call
  const refDb = collection(firestoreInit, 'users');
  const refDb2 = collection(firestoreInit, 'homeSections');

  const refDocUser = doc(refDb, userID);
  const refDocSections = doc(refDb2, 'qKIIraPamZpXDCDCj72M');

  await updateDoc(refDocUser, { [`sectionsData.priceToPay`]: priceToPay });
  await updateDoc(refDocSections, {
    [`homeSections.${currSection.sectionID}.sectionCTA.allPrices`]:
      arrayUnion(priceToPay),
  });

  // ----------- set No Return
};
