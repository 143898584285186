// ---------- set Packs
import { doc, updateDoc, CollectionReference } from 'firebase/firestore';

// ---------- set Internals
import { TuserAuthData, TuserDB, TuserInfo } from '#types/TuserInfo';

// ---------- set Local Types
type Tprops = {
  arrUser: TuserInfo[];
  refColl: CollectionReference<TuserInfo>;
  userAuthData: TuserAuthData;
};

// ---------- export Function
export const updateUser = async (props: Tprops) => {
  // ---------- get Props
  const { arrUser, refColl, userAuthData } = props;

  // ---------- get Doc to Update
  const currUser = arrUser[0].userDB.userID;
  const userDoc = doc(refColl, currUser);

  const userDB: TuserDB = {
    userID: currUser,
    userCreatedAt: arrUser[0].userDB.userCreatedAt,
  };

  // ---------- set Update Doc
  const dataToUpdate = { userAuthData };
  await updateDoc(userDoc, dataToUpdate);

  const dataSuccess = {
    sectionsData: arrUser[0].sectionsData,
    userAuthData,
    userDB,
  };

  return dataSuccess;
};
