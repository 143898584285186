// ----------- import Packs
import { User } from 'firebase/auth';

// ----------- import Internals
import { setDataBeforeAsync } from './setDataBeforeAsync';
import { setDataSuccess } from './setDataSuccess';
import { asyncFn1 } from './asyncFn1';
import { setError } from './setError';

// ----------- set Main Function
export const googleSignin = async (userTest?: User) => {
  setDataBeforeAsync();

  const dataSuccess = await asyncFn1(userTest).catch(setError);

  if (dataSuccess) await setDataSuccess(dataSuccess);

  // ----------- No Return
};
