// ----------- import Internals
import { setDataBeforeAsync } from './setDataBeforeAsync';
import { setDataSuccess } from './setDataSuccess';
import { Tfield } from 'src/sections/types';
import { setError } from './setError';
import { asyncFb1 } from './asyncFb1';

// ----------- set Main Function
export const setCTAItems = async (field: Tfield) => {
  setDataBeforeAsync();

  await asyncFb1(field).catch(setError);

  setDataSuccess();
};
