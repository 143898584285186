// ----------- import Packs

// ----------- import Internals
import { setData } from '#ctData';
import { DocumentSnapshot } from 'firebase/firestore';

// ----------- set Function
export const setDataSuccess = async () => {
  // ----------- set Data
  // const arrSections: ThomeSections[] = [];
  // const resolvePromise = dataSuccess.data();
  // ----------- check Data
  // if (!resolvePromise)
  //   throw new Error('check Data - resolvePromise not found!');
  // ----------- set Data
  // const homeSections: { [key: string]: {} } = resolvePromise.homeSections;
  // ----------- check Data
  // if (!homeSections) throw new Error('check Data - homeSections not found!');
  // for (const key in homeSections) {
  //   const item = homeSections[key];
  //   arrSections.push(item);
  // }
  // arrSections.sort(
  //   (a, b) => Number(a.sectionPosition) - Number(b.sectionPosition),
  // );
  // const change1 = { path: 'sections.list.condList', val: true };
  // const change2 = {
  //   path: 'sections.list.arrSections',
  //   val: arrSections,
  // };
  // ----------- change Data
  // setData([change1, change2]);
  // ----------- No Return
};
