// import { useFonts as useFonts1 } from '@expo-google-fonts/nunito';
// import { useFonts as useFonts2,  } from '@expo-google-fonts/montserrat';
import { useFonts as useFonts } from '@expo-google-fonts/lato';
import { setFont } from './setFont';

// export const fonts = {
//   googleFonts: {
//     // useFonts: [useFonts1, useFonts2],
//     useFonts: useFonts1,
//     fonts: { ...setFont() },
//     fontsMap: {
//       xLi: 'Nunito_200ExtraLight',
//       xLiIt: 'Nunito_200ExtraLight_Italic',
//       li: 'Nunito_300Light',
//       liIt: 'Nunito_300Light_Italic',
//       reg: 'Nunito_400Regular',
//       regIt: 'Nunito_400Regular_Italic',
//       sb: 'Nunito_600SemiBold',
//       sbIt: 'Nunito_600SemiBold_Italic',
//       bd: 'Nunito_700Bold',
//       bdIt: 'Nunito_700Bold_Italic',
//       xBd: 'Nunito_800ExtraBold',
//       xBdIt: 'Nunito_800ExtraBold_Italic',
//       bl: 'Nunito_900Black',
//       blIt: 'Nunito_900Black_Italic',
//     },
//   },
// };

export const SetFonts = ({ children }: { children: JSX.Element }) => {
  const [fontsLoaded] = useFonts({ ...setFont() });
  // const [fontsLoaded2] = useFonts2({ ...setFont() });

  // const fonts = fontsLoaded1 && fontsLoaded2;

  return fontsLoaded ? children : null;
};
