// ----------- import Internals
import { ViewStyle } from 'react-native';
import { Talign } from './types';

// ----------- set export Component
export const setAlign = (props: Talign): ViewStyle => {
  // ----------- set Props
  const { i_center_2, i_center_h, i_left_h, i_right_h, i_row } = props;
  const { i_center_v, i_top_v, i_bottom_v, i_space_between } = props;

  // ----------- set Conditional Alignments
  const condHorizontal = i_row ? 'justifyContent' : 'alignItems';
  const condVertical = i_row ? 'alignItems' : 'justifyContent';

  const hvCenter: ViewStyle = {
    justifyContent: 'center',
    alignItems: 'center',
  };
  const hvNoCenter: ViewStyle = {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  };
  const condHVC = i_center_2 ? hvCenter : hvNoCenter;

  // ----------- set Horizontal Alignments
  const hCenter: ViewStyle = { [condHorizontal]: 'center' };
  const condHC: ViewStyle = i_center_h ? hCenter : {};

  const hLeft: ViewStyle = { [condHorizontal]: 'flex-start' };
  const condHL: ViewStyle = i_left_h ? hLeft : {};

  const hRight: ViewStyle = { [condHorizontal]: 'flex-end' };
  const condHR: ViewStyle = i_right_h ? hRight : {};

  // ----------- set Vertical Alignments
  const vCenter: ViewStyle = { [condVertical]: 'center' };
  const condVC: ViewStyle = i_center_v ? vCenter : {};

  const vTop: ViewStyle = { [condVertical]: 'flex-start' };
  const condVT: ViewStyle = i_top_v ? vTop : {};

  const vBottom: ViewStyle = { [condVertical]: 'flex-end' };
  const condVB: ViewStyle = i_bottom_v ? vBottom : {};

  // ----------- set Space Between
  const spacBtw: ViewStyle = { justifyContent: 'space-between' };
  // ------ OBS: there's 'space-between' only in 'justifyContent'
  // const spacBtw: ViewStyle = { [condSpaceBtw]: 'space-between' };
  const condSB: ViewStyle = i_space_between ? spacBtw : {};

  // ----------- set Return
  const obj1: ViewStyle = { ...condHVC, ...condHC, ...condHL, ...condHR };
  const alignView: ViewStyle = {
    ...obj1,
    ...condVC,
    ...condVT,
    ...condVB,
    ...condSB,
  };

  return alignView;
};
