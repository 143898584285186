// ----------- import Packs
import React from 'react';
import { View } from 'react-native';

// ----------- set Local Types
type Telem = React.ReactElement;
type Tprops = React.PropsWithChildren<{ gapStl: {}; i_row?: boolean }>;

// ----------- set Function to Export
export const setChildren = (props: Tprops) => {
  // ----------- set Props
  const { children } = props;

  // ----------- set New Children
  // @ts-ignore
  const ChildrenArr: Telem[] = React.Children.toArray(children);
  const NewChildren = ChildrenArr.map(childMap(props));

  // ----------- set Return
  return NewChildren;
};

// ----------- set Each Child Change
const childMap = (props: Tprops) => (item: Telem, idx: number) => {
  // ----------- set Props
  const { children, gapStl, i_row } = props;

  // ----------- set Condition
  const checkItems = React.Children.count(children) > 1;
  const condGap = checkItems && idx > 0;
  const newChild = React.cloneElement(item, { parent_row: i_row && 'true' });

  // ----------- set Returns
  if (!condGap) return newChild;

  return (
    <React.Fragment key={idx}>
      <View style={[gapStl]} />
      {newChild}
    </React.Fragment>
  );
};
