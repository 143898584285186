// ----------- import Packs
import React from 'react';
import { View } from 'react-native';
import { ErrorBoundary } from './ErrorBoundary';

// ----------- import Internals
import { setChildren } from './setChildren';
import { setPadding } from './setPadding';
import { setAlign } from './setAlign';
import { setSize } from './setSize';
import { setView } from './setView';
import { TAutoView } from './types';
import { setGap } from './setGap';
import { setRow } from './setRow';

// ----------- set export Component
export const AutoView = (props: TAutoView) => {
  // ----------- set Props
  const { children, i_row } = props;

  // ----------- set Responsive Start

  // ----------- set Styles Steps
  const rowView = setRow(i_row);
  const gapStl = setGap(props);
  const sizeStl = setSize(props);
  const paddingStl = setPadding(props);
  const alignView = setAlign(props);

  // ----------- set Custom View
  const infoView = { alignView, paddingStl, rowView, sizeStl, ...props };
  const viewStl = setView(infoView);

  // ----------- set New Children
  const childrenStl = { children, gapStl, i_row };
  const newChildren = setChildren(childrenStl);

  // ----------- set Return
  return (
    <ErrorBoundary>
      <View {...props} style={viewStl}>
        {newChildren}
      </View>
    </ErrorBoundary>
  );
};
