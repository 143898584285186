// ----------- import Packs
import React from 'react';
import { Image, ImageStyle } from 'react-native';

// ----------- import Internals
import { AutoView } from 'src/config/theme/AutoView';
import src1 from '#images/mobile.png';
import src2 from '#images/arrows.png';

// ----------- set Component
export const A_Welcome = () => {
  // ----------- set Return
  return (
    <AutoView i_center_2 style={{ height: '100vh' }}>
      <AutoView fill_height i_center_2>
        <Image source={src1} style={stlImg} />
      </AutoView>

      <Image source={src2} style={stlImg2} />
    </AutoView>
  );
};

// ----------- set Local Styles
const stlImg: ImageStyle = {
  width: 500,
  height: 500,
  resizeMode: 'center',
};

const stlImg2: ImageStyle = {
  width: 20,
  height: 20,
  resizeMode: 'center',
};
