// ----------- import Internals
import { setDataSuccess } from './setDataSuccess';
import { setDataBeforeAsync } from './setDataBeforeAsync';
import { setError } from './setError';
import { asyncFb1 } from './asyncFb1';
import { TsectionsItems } from 'src/sections/types';
import { mockData } from './mockData';

// ----------- set Main Function
export const getItems = async () => {
  if (false) return setDataSuccess(mockData);

  setDataBeforeAsync();

  const dataSuccess = await asyncFb1().catch(setError);

  setDataSuccess(dataSuccess);
};
