// ---------- import Packs
import React from 'react';
import { TouchableOpacity, useWindowDimensions } from 'react-native';

// ---------- import Internals
import { TextLabel } from 'src/config/theme/TextLabel';
import { AutoView } from 'src/config/theme/AutoView';
import { useData } from '#ctData';

// ---------- import Assets
import { stlCTARefWidth, stlCTATitleClosed, stlTxtClosed } from '../../styles';
import { CurvesStripes } from 'src/sections/assets/CurvesStripes';
import { stlCTA, stlCTAClosed } from '../../styles';
import { Private } from './Private';
import { Public } from './Public';

// ---------- set Main Function
export const CallToAction = ({ sectionID }: { sectionID: string }) => {
  // ---------- set Local State
  const [sttOpenCTA, setOpenCTA] = React.useState(false);
  const { width } = useWindowDimensions();

  // ---------- set Actions
  const toggleCTA = () => {
    setOpenCTA(!sttOpenCTA);
    // !sttOpenCTA && ctaCount() - #ATTENTION! FALTA
  };

  const CondComponent = () => {
    const userID = useData(ct => ct.projectData?.authUser?.userDB?.userID);

    return (
      <>
        {userID ? (
          <Private sectionID={sectionID} />
        ) : (
          <Public sectionID={sectionID} />
        )}
      </>
    );
  };
  // ---------- set Return
  return (
    <AutoView style={stlCTARefWidth(width)}>
      {sttOpenCTA && (
        <AutoView i_center_h fill_width>
          <TouchableOpacity
            onPress={toggleCTA}
            style={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TextLabel style={stlTxtClosed}>fechar</TextLabel>
          </TouchableOpacity>

          <AutoView style={stlCTA} i_center_2 fill_width>
            <CurvesStripes />

            <AutoView fill_width i_center_h pad_4={30} pad_v={60}>
              <CondComponent key={0} />
            </AutoView>
          </AutoView>
        </AutoView>
      )}

      {!sttOpenCTA && (
        <AutoView i_center_h fill_width>
          <TouchableOpacity
            onPress={toggleCTA}
            style={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TextLabel style={stlCTATitleClosed(width)}>
              _quero participar!
            </TextLabel>

            <AutoView style={stlCTAClosed} />
          </TouchableOpacity>
        </AutoView>
      )}
    </AutoView>
  );
};
