import { TuserInfo } from '#dbTypes/TuserInfo';

// ----------- set Project Data Object
export const projectData: TprjDt = {
  sideRight: {
    open: false,

    arrMenu2: [],
  },
  authUser: { loading: false },
};
type TprjDt = {
  sideRight: {
    open: boolean;
    component?: any;

    arrMenu2: {
      icon: string;
      label: string;
      condActive?: string;
      onPress: Function;
    }[];
  };
  authUser: {
    userInfo?: TuserInfo;
    userId?: string;
    loading: boolean;
    sectionsData?: { priceToPay: number };
  };
  // navControl?: { bigSc?: React.ReactNode };
};
