// ----------- import Packs
import React from 'react';
import { Text, View } from 'react-native';

// ----------- set Local Types
type Tprops = {} & React.PropsWithChildren;
type Tstate = { error: null | string; errorInfo: null | TerrorInfo };
type TerrorInfo = { componentStack: string };

// ----------- export Component
export class ErrorBoundary extends React.Component<Tprops, Tstate> {
  constructor(props: Tprops) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: any, errorInfo: TerrorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({ error: error, errorInfo: errorInfo });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      console.log('errorInfo', this.state.errorInfo);
      // Error path
      return (
        <View>
          <Text style={{ color: 'red' }}>Something went wrong.</Text>
          <Text style={{ color: 'red' }}>
            {this.state.error && this.state.error.toString()}
            {this.state.errorInfo.componentStack}
          </Text>
        </View>
      );
    }

    // Normally, just render children
    return <>{this.props.children}</>;
  }
}
