import { TextStyle, ViewStyle } from 'react-native';

import { baseTextColor } from 'src/config/theme/base/colors';
import { setStl } from 'src/config/theme/setStl';

// -------------------------
// -------------- set Styles
// -------------------------

// ---------------- Base Texts
// ---------------------------

export const stlTitle = setStl({
  fullBase: {
    textAlign: 'center',
    color: 'white',
    fontSize: 80,
    fontWeight: '600',
  },
  1000: {
    fontSize: 80,
    lineHeight: 90,
  },
  800: {
    fontSize: 56,
    lineHeight: 68,
  },
  600: {
    fontSize: 38,
    lineHeight: 44,
    paddingHorizontal: 20,
  },
});

export const stlSubTitle = setStl({
  fullBase: {
    textAlign: 'center',
    color: 'white',
    fontSize: 50,
  },
  1000: {
    fontSize: 50,
    lineHeight: 68,
    fontWeight: '600',
  },
  800: {
    fontSize: 40,
    lineHeight: 48,
    fontWeight: '600',
  },
  600: {
    fontSize: 28,
    lineHeight: 40,
    fontWeight: '600',
  },
});

export const stlParagraph = setStl({
  fullBase: {
    textAlign: 'center',
    fontSize: 22,
    fontWeight: '300',
    lineHeight: 36,
    color: 'white',
  },
  1000: {
    fontSize: 22,
    fontWeight: '300',
    lineHeight: 36,
  },
  800: {
    fontSize: 20,
    fontWeight: '300',
    lineHeight: 30,
  },
  600: {
    fontSize: 16,
    fontWeight: '300',
    lineHeight: 24,
  },
});

export const stlCTATitleClosed = setStl({
  fullBase: {
    textAlign: 'center',
    fontSize: 20,
    color: '#64A',
  },
  800: { fontSize: 16 },
});

export const stlCTATitle = setStl({
  fullBase: {
    zIndex: 10,
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#ccc',
    padding: 20,
    fontSize: 25,
  },
});

export const stlCTATitle2: TextStyle = {
  ...stlCTATitle,
  fontSize: 18,
};

export const stlTxtClosed = {
  fontWeight: '600',
  textAlign: 'center',
  marginBottom: 4,
  color: '#222',
  size: 16,
};

// ------------ Containers & Boxes
// -------------------------------
export const stlLogo = setStl({
  fullBase: {
    width: 100,
    height: '100%',
    resizeMode: 'center',
  },
  600: {
    width: 60,
    height: 80,
    resizeMode: 'center',
  },
});

const stlBorder: ViewStyle = {
  borderWidth: 1,
  borderColor: '#aaa',
  borderRadius: 30,
};

export const stlContainer = setStl({
  fullBase: {
    paddingHorizontal: 50,
    width: 1000,
  },
  1000: {
    paddingHorizontal: 30,
    width: '100%',
  },
  600: {
    paddingHorizontal: 14,
    width: '100%',
  },
});

export const sizeVideo = (width: number) => {
  const res1 = width / 2;

  if (res1 > 620) return 620;
  if (res1 < 400) return 400;

  return res1;
};

export const stlCTARefWidth = setStl({
  fullBase: {
    maxWidth: 400,
    width: '100%',
  },
  600: {
    width: '100%',
    paddingHorizontal: 40,
  },
});

export const stlCTA: ViewStyle = {
  overflow: 'hidden',

  ...stlBorder,
  borderWidth: 2,
  borderColor: '#555',

  width: '100%',
};

export const stlCTAClosed: ViewStyle = {
  marginBottom: 10,
  ...stlBorder,
  marginTop: 8,
  padding: 4,

  width: '100%',
};

export const stlOutroTeste = (width: number) =>
  setStl({
    fullBase: { width: '100%', backgroundColor: 'red' },
    1000: { opacity: width / 1000 },
    800: { opacity: 0.8 },
  })(width);

export const stlCTATxt: TextStyle = {
  zIndex: 10,
  color: baseTextColor,
  textAlign: 'center',
  fontSize: 18,
};

export const stlTxBox1: TextStyle = { maxWidth: '100%' };

export const stlTxBox2: TextStyle = { maxWidth: 220 };

export const stlInput: TextStyle = {
  ...stlBorder,
  paddingHorizontal: 10,
  paddingVertical: 4,
  color: 'rgba(255,255,255,0.3)',
  marginBottom: 10,
  marginTop: 8,
};

// --------------- Forms & Buttons
// -------------------------------
export const stlBtn = setStl({
  fullBase: {
    backgroundColor: '#64A',
    borderRadius: 44,
    alignItems: 'center',
    justifyContent: 'center',

    width: 120,
    height: 42,
  },
  600: {
    width: 100,
    height: 34,
  },
});

export const stlBtnLabel = setStl({
  fullBase: {
    fontWeight: 'bold',
    color: '#fff',
    fontSize: 18,
  },
  600: {
    fontSize: 14,
  },
});

export const stlBtn2 = setStl({
  fullBase: {
    backgroundColor: '#40217f',

    borderRadius: 44,
    alignItems: 'center',
    justifyContent: 'center',

    width: 80,
    height: 28,
  },
});

export const stlBtnLabel2 = setStl({
  fullBase: {
    fontWeight: 'bold',
    color: '#fff',
    fontSize: 10,
  },
});

export const stlBtCounterControl = setStl({
  fullBase: {
    alignItems: 'center',
    justifyContent: 'center',

    width: 26,
    height: 26,

    backgroundColor: '#2e0951',
    borderColor: '#40217f',
    borderWidth: 2,
    borderRadius: 6,

    paddingBottom: 4,
  },
});

export const stlBtCounterTxt = setStl({
  fullBase: {
    fontSize: 16,
    color: 'white',
    fontWeight: '100',
  },
});
