// ---------- import Packs
import React, { PropsWithChildren } from 'react';
import { Text, TextStyle } from 'react-native';

// ---------- import Internals
// import { localFont } from '#theme/fonts';
import { baseTextColor } from './base/colors';

// ---------- set Local Types
type Tprops = {
  style?: TextStyle | TextStyle[];
  dots1Line?: boolean;
  size?: number;
  bold?: boolean;
  red?: boolean;
  center?: boolean;
  color?: string;
} & PropsWithChildren<{}>;

// ----------- set Function
export const TextLabel = (props: Tprops) => {
  // ----------- set Props
  const { dots1Line, size, bold, red, center, color } = props;

  const stl: TextStyle = {
    fontSize: size ?? 12,
    fontWeight: bold ? 'bold' : 'normal',
    // fontFamily: localFont.regular,

    color: red ? 'red' : baseTextColor,
    width: '100%',
    textAlign: center ? 'center' : 'auto',
    ...props.style,
  };
  const condColor: TextStyle | {} = color ? { color } : {};

  // ---------- set Return
  return (
    <Text
      numberOfLines={dots1Line ? 1 : undefined}
      ellipsizeMode={dots1Line ? 'tail' : undefined}
      style={[stl, condColor]}
      {...props}
    />
  );
};
