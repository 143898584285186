import React from 'react';
import { Linking, TouchableOpacity } from 'react-native';

import { TextLabel } from 'src/config/theme/TextLabel';
import { AutoView } from 'src/config/theme/AutoView';
import { IcoDiscord } from '../../assets/IcoDiscord';
import { IcoTwitter } from '../../assets/IcoTwitter';
import { TsectionDB } from 'src/sections/types';
import { stlCTATxt, stlTxBox2 } from '../../styles';
import { socialCount } from './helpers';

type Tprops = { currSection: TsectionDB; sectionID: string };

export function SocialCall({ currSection, sectionID }: Tprops) {
  const btnTwitter = () => {
    const oldCount = Number(currSection.sectionCTA.twitterCount);
    const count = String(oldCount + 1);

    socialCount({ name: 'twitterCount', count, sectionID });

    const twttUrl = 'https://twitter.com/_morfos';
    Linking.openURL(twttUrl);
  };

  // const btnDiscord = () => {
  //   const oldCount = Number(currSection.sectionCTA.discordCount);
  //   const count = String(oldCount + 1);
  //   socialCount({ name: 'discordCount', count, sectionID });

  //   const dscrdUrl = 'https://discord.com/';
  //   Linking.openURL(dscrdUrl);
  // };

  return (
    <AutoView i_center_2 i_space={16} pad_4={0} no_fill>
      <TextLabel center style={[stlCTATxt, { fontSize: 16 }, stlTxBox2]}>
        Acompanhe as atualizações seguindo-nos nas redes:
      </TextLabel>

      <AutoView i_row i_center_v>
        <TouchableOpacity onPress={btnTwitter}>
          <IcoTwitter width={32} height={32} fill={'#fff'} />
        </TouchableOpacity>

        {/* <TouchableOpacity onPress={btnDiscord} style={{ marginLeft: 20 }}>
          <IcoDiscord width={32} height={32} fill={'#fff'} />
        </TouchableOpacity> */}
      </AutoView>
    </AutoView>
  );
}
