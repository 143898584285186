// ----------- import Packs
import { ViewStyle } from 'react-native';
import { setStlArr } from './setStlArr';
import { Tstyle, TstyleInfo, Tview } from './types';

// ----------- set Local Types
type Tprops = Tview & {
  rowView: ViewStyle;
  sizeStl: ViewStyle;
  alignView: ViewStyle;
  paddingStl: ViewStyle;
} & TstyleInfo;

// ----------- set export Component
export const setView = (props: Tprops) => {
  // ----------- set Props
  const { style, rowView, sizeStl, bdRadius } = props;
  const { alignView, paddingStl, bgColor, red } = props;

  // ----------- set View
  const initStyles: ViewStyle = { alignItems: 'flex-start' };
  const condBgColor: ViewStyle | {} = bgColor
    ? { backgroundColor: bgColor }
    : {};
  const condRed: ViewStyle = red ? { backgroundColor: 'red' } : condBgColor;
  const condBdRadius: ViewStyle | {} = bdRadius
    ? { borderRadius: bdRadius }
    : {};

  // ----------- set Style if Array or not
  const stlObj = setStlArr(style);

  // ----------- set Return
  const viewStl = {
    // init to be overridden
    ...initStyles,

    // processed changes
    ...sizeStl,
    ...rowView,
    ...alignView,
    ...condBdRadius,
    ...paddingStl,

    // end to override others
    ...stlObj,

    // last to override stlObj
    ...condRed,
  };

  return viewStl;
};
