// ----------- import Packs
import { doc } from 'firebase/firestore';
import { User, UserInfo } from 'firebase/auth';

// ----------- import Internals
import { TuserAuthData, TuserInfo } from '#types/TuserInfo';
import { googleAuth } from './googleAuth';
import { addNewUser } from './addNewUser';
import { updateUser } from './updateUser';
import { getDbInfo } from './getDbInfo';
import { checkData, ezLog } from '#utils';

// ---------- set Async Call
export const asyncFn1 = async (userTest?: User) => {
  if (userTest) return await getUserData(userTest);

  // ---------- set Google Auth Permission
  const userData = await googleAuth();

  const dataSuccess = await getUserData(userData);
  return dataSuccess;
};

export const getUserData = async (userData: UserInfo) => {
  // ---------- set Collection Reference
  const userEmail = userData.email;
  const dbInfo = await getDbInfo(userEmail);
  const { refColl, condNewUser, arrUser } = dbInfo;

  // ---------- set user Object to set or update

  const userAuthData: TuserAuthData = {
    userName: userData.displayName,
    userImg: userData.photoURL,
    userEmail: userEmail ?? '',
  };

  // ---------- set Doc References
  const refDoc = doc(refColl);

  let dataSuccess: TuserInfo | undefined;

  // ---------- set New User
  if (condNewUser) dataSuccess = await addNewUser({ userAuthData, refDoc });

  // ---------- set Update User
  if (!condNewUser)
    dataSuccess = await updateUser({ arrUser, refColl, userAuthData });

  // ---------- check Data
  if (!dataSuccess) return checkData('dataSuccess');

  // ------ return SUCCESS
  // return userAuthData;

  return dataSuccess;
};
