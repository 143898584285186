import React from 'react';
import { TouchableOpacity, useWindowDimensions, View } from 'react-native';

import {
  stlBtCounterControl,
  stlBtCounterTxt,
  stlBtn2,
  stlBtnLabel2,
  stlCTATitle,
  stlCTATxt,
  stlTxBox1,
} from '../../styles';
import { TextLabel } from 'src/config/theme/TextLabel';
import { AutoView } from 'src/config/theme/AutoView';
import { SocialCall } from './SocialCall';
import { useData } from '#ctData';
import { setValue } from './actions/setValue';
import { baseTextColor } from 'src/config/theme/base/colors';

export const Private = ({ sectionID }: { sectionID: string }) => {
  const { width } = useWindowDimensions();

  // ---------- set Data to Watch
  const arrSections = useData(ct => ct.sections.list.arrSections);

  // ---------- set Data
  const currSection = arrSections.find((i: any) => i.sectionID === sectionID);

  // ---------- check Data
  if (!currSection) throw new Error('check Data - currSection not found!');

  // ---------- set Return
  return (
    <AutoView fill_width i_center_2 i_space={60}>
      <AutoView fill_width i_center_2 i_space={30}>
        <TextLabel
          style={[stlCTATitle(width), { marginBottom: -20, padding: 0 }]}
        >
          BEM VINDO!
        </TextLabel>

        <TextLabel center style={[stlCTATxt, stlTxBox1]}>
          Você já tem um pré cadastro na Morfos e te avisaremos assim que
          tivermos novidades.
        </TextLabel>

        <TextLabel size={18} color="white" bold center>
          Quer ter acesso antecipado?
        </TextLabel>

        <TextLabel size={16} color="#ccc" center>
          Defina abaixo o valor que você teria a disponibilidade de pagar pra
          ter a chance de entrar numa{' '}
          <TextLabel size={16} color="white">
            lista de prioridade
          </TextLabel>{' '}
          para ter acesso mensal ao Alfa privado:
        </TextLabel>

        <AutoView fill_width i_center_2 i_space={5}>
          <CounterMoney currSection={currSection} />
          <TextLabel
            style={{
              color: baseTextColor,
              fontSize: 11,
              width: 200,
              textAlign: 'center',
            }}
          >
            O pagamento será solicitado apenas após receber o convite
          </TextLabel>
        </AutoView>
      </AutoView>

      <SocialCall currSection={currSection} sectionID={sectionID} />
    </AutoView>
  );
};

const CounterMoney = (props: any) => {
  // ---------- set Props
  const { currSection } = props;

  // ---------- set Component Width Dimension
  const { width } = useWindowDimensions();

  // ---------- set User priceToPay
  const priceToPay = useData(
    ct => ct.projectData.authUser.sectionsData?.priceToPay,
  );

  // ---------- set Local State
  const [sttCount, setCount] = React.useState(priceToPay ?? 5);
  const [sttMsg, setMsg] = React.useState(false);

  // ---------- set Actions
  const increment = () => {
    sttCount && setMsg(false);
    setCount(sttCount + 5);
  };
  const decrement = () => {
    sttCount && setMsg(false);
    sttCount > 0 && setCount(sttCount - 5);
  };
  const btnDefine = () => {
    if (!condDefinePrice) return setMsg(true);
    sttCount && setMsg(false);
    setValue(currSection, sttCount);
  };

  // ---------- set Conditions
  const condDefinePrice = priceToPay === sttCount ? false : true;
  const stlCondDisable = !condDefinePrice
    ? { opacity: 0.3, filter: 'grayscale(0.6)' }
    : {};
  const condShow = sttCount ? 'R$' + sttCount : 'Grátis';

  // ---------- set Return
  return (
    <AutoView i_center_2 i_space={10}>
      <AutoView i_row i_center_2 i_space={12}>
        <TouchableOpacity
          style={stlBtCounterControl(width)}
          onPress={decrement}
        >
          <TextLabel style={stlBtCounterTxt(width)}>-</TextLabel>
        </TouchableOpacity>

        <TextLabel color="white" size={18}>
          {condShow}
        </TextLabel>

        <TouchableOpacity
          style={stlBtCounterControl(width)}
          onPress={increment}
        >
          <TextLabel style={stlBtCounterTxt(width)}>+</TextLabel>
        </TouchableOpacity>
      </AutoView>

      <TouchableOpacity
        style={[stlBtn2(width), stlCondDisable]}
        onPress={btnDefine}
      >
        <TextLabel style={stlBtnLabel2(width)}>Definir</TextLabel>
      </TouchableOpacity>
      {sttMsg && (
        <TextLabel color="red" size={14}>
          Valor já definido!
        </TextLabel>
      )}
    </AutoView>
  );
};
