// ----------- set Local Types
import { Tpad } from './types';

// ----------- set export Component
export const setPadding = (props: Tpad) => {
  // ----------- set Props
  const { pad_4, pad_v, pad_h, pad_top } = props;
  const { pad_bottom, pad_left, pad_right } = props;

  // ----------- set Padding Props
  const padT = (val: number) => ({ paddingTop: val });
  const condPadT = pad_top ? padT(pad_top) : {};

  const padB = (val: number) => ({ paddingBottom: val });
  const condPadB = pad_bottom ? padB(pad_bottom) : {};

  const padL = (val: number) => ({ paddingLeft: val });
  const condPadL = pad_left ? padL(pad_left) : {};

  const padR = (val: number) => ({ paddingRight: val });
  const condPadR = pad_right ? padR(pad_right) : {};

  // ----------- set Padding Groups
  const condPadV = pad_v ? { ...padT(pad_v), ...padB(pad_v) } : {};
  const condPadH = pad_h ? { ...padL(pad_h), ...padR(pad_h) } : {};
  const condPad4 = pad_4
    ? { ...padT(pad_4), ...padB(pad_4), ...padL(pad_4), ...padR(pad_4) }
    : {};

  // ----------- set valTheme
  const obj1 = { ...condPadT, ...condPadB, ...condPadL, ...condPadR };
  const valTheme = { ...condPad4, ...obj1, ...condPadV, ...condPadH };

  // ----------- set return
  return valTheme;
};
