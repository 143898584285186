import { StyleProp, StyleSheet } from 'react-native';
import { ImageStyle, TextStyle, ViewStyle } from 'react-native';

// type TobjStlProp = { [key: string]: StyleProp<any> };
type Ttriple = ImageStyle | ViewStyle | TextStyle;

type Tval<T> = T[keyof T];
type Tprops<T> = {
  ['fullBase']: StyleProp<Tval<T>>;
  [size: string]: StyleProp<Tval<T>>;
};
type Tset<T> = StyleSheet.NamedStyles<Tprops<T>>;
// type TnStl2<T> = StyleProp<Tprops<T>>;

export function setStl<T extends Tset<T>>(sizes: T) {
  return (width: number): Tval<T> => {
    // ---------- set init body
    const newObj: { [key: string]: any } = { ...sizes };
    delete newObj['fullBase'];
    const stlKeys = Object.keys(newObj);

    stlKeys.push('0');

    stlKeys.sort((a, b) => {
      const a2 = Number(a);
      const b2 = Number(b);
      return b2 - a2;
    });

    let sizeProps: Ttriple = {};

    stlKeys.reduce((a, b) => {
      const a2 = Number(a);
      const b2 = Number(b);

      if (width <= a2 && width > b2) {
        const addStl = newObj[a];
        sizeProps = { ...sizeProps, ...addStl };
      }
      return b;
    });

    const checkSize = Object.values(sizeProps).length > 0;

    const setFull = sizes['fullBase'];
    const returnFull = StyleSheet.flatten(setFull);

    // @ts-ignore
    if (!checkSize) return returnFull;

    const resultSize = { ...returnFull, ...sizeProps };

    // @ts-ignore
    return resultSize;
  };
}
