import { ViewStyle } from 'react-native';
import { Tstyle } from './types';

export const setStlArr = (style: Tstyle | Tstyle[]) => {
  if (Array.isArray(style)) {
    let newStl: ViewStyle | undefined = {};

    style.map(item => {
      let key: keyof ViewStyle;
      for (key in item) {
        const element = item[key];
        // @ts-ignore
        newStl[key] = element;
      }
    });

    return newStl;
  }
  const emptyStl: ViewStyle = {};
  if (!style) return emptyStl;

  return style;
};
