// ----------- import Packs
import { collection, doc, updateDoc } from 'firebase/firestore';

// ----------- import Internals
import { firestoreInit } from '#firebase';
import { getCtData } from '#ctData';
import { Tfield } from 'src/sections/types';

// ---------- set Local Type

export const asyncFb1 = async (field: Tfield) => {
  // ---------- set Data
  const refCollection = 'homeSections';
  // const iptsChanges = getCtData(ct => ct.sections.form.iptsChanges);

  // ---------- set Async Call
  const refDb = collection(firestoreInit, refCollection);

  const refDoc = doc(refDb, 'qKIIraPamZpXDCDCj72M');
  await updateDoc(refDoc, {
    [`homeSections.${field.sectionID}.sectionCTA.${field.name}`]: field.count,
  });

  // ----------- set Return
  // return dataSuccess;

  // -------------------------------
  // -------------------------------
  // -------- #FALTA ATUALIZAR CTA
  // -------------------------------
  // -------------------------------
};
