// ----------- import Packs

// ----------- import Internals
import { setData } from '#ctData';
import { DocumentSnapshot } from 'firebase/firestore';

// ----------- set Function
export const setDataSuccess = async (priceToPay: number) => {
  // ----------- set Data
  const change1 = {
    path: 'projectData.authUser.sectionsData.priceToPay',
    val: priceToPay,
  };

  // ----------- change Data
  setData([change1]);

  // ----------- No Return
};
