import React from 'react';
import { TextLabel } from 'src/config/theme/TextLabel';
import { AutoView } from 'src/config/theme/AutoView';
import { Tquestion } from '../../types';
import { stlParagraph, stlSubTitle } from 'src/sections/styles';
import { useWindowDimensions } from 'react-native';

type Tprops = { questionItem: Tquestion & { sectionID: string } };

export const EachQuestion = ({ questionItem }: Tprops) => {
  const { subtitle, paragraph, sectionID } = questionItem;
  const { width } = useWindowDimensions();

  return (
    <AutoView pad_bottom={40} i_space={20} i_center_h>
      {/* <TextLabel color={'#999'} size={30} center> */}
      <TextLabel style={stlSubTitle(width)}>{subtitle['pt-br']}</TextLabel>

      <AutoView width={'90%'}>
        {/* <TextLabel size={30} center> */}
        <TextLabel style={stlParagraph(width)}>{paragraph['pt-br']}</TextLabel>
      </AutoView>
    </AutoView>
  );
};
