import React from 'react';
import { Image, ImageStyle, Text } from 'react-native';
import { TextStyle, useWindowDimensions } from 'react-native';
import { AutoView } from 'src/config/theme/AutoView';

import { TextLabel } from 'src/config/theme/TextLabel';
import { sizeVideo, stlParagraph, stlSubTitle, stlTitle } from '../styles';
import { CallToAction } from './CallToAction';

import src5 from '#images/mobile4.png';

export const Section4 = () => {
  const { width } = useWindowDimensions();
  const stlMobile1: ImageStyle = {
    resizeMode: 'contain',
    width: sizeVideo(width),
    height: sizeVideo(width),
  };

  return (
    <AutoView fill_width i_center_h i_space={100} pad_bottom={200}>
      <Title />

      <Image source={src5} style={stlMobile1} />

      <AutoView pad_bottom={40} i_space={20} i_center_h>
        <TextLabel style={stlSubTitle(width)}>
          {'O Melhor dos #2_Mundos'}
        </TextLabel>

        <AutoView width={'90%'}>
          <TextLabel style={stlParagraph(width)}>
            {
              'Com o Morfos Builder você tem em mãos a praticidade de uma interface visual sem abrir mão do potencial do código, ou seja, conforme você monta o sistema via interface, você já tem um código estruturado de qualidade pra entregar pra qualquer desenvolvedor avançado sempre que quiser, quando quiser ou precisar.'
            }
          </TextLabel>
        </AutoView>
      </AutoView>

      <CallToAction sectionID="sss4" />
    </AutoView>
  );
};

function Title() {
  const { width } = useWindowDimensions();

  const stlTitle2: TextStyle = {
    ...stlTitle(width),
    paddingLeft: 0,
    textAlign: 'center',
  };

  return (
    <AutoView i_center_2 i_space={40} fill_2>
      <Text style={stlTitle2}>
        Evite
        <Text style={{ fontWeight: '300' }}>
          {' desperdício de tempo com '}
        </Text>
        <Text>retrabalhos </Text>
        <Text style={{ fontWeight: '300' }}>{'desnecessários.'}</Text>
      </Text>
    </AutoView>
  );
}
