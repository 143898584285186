// ----------- import Packs
import { collection, doc, getDoc } from 'firebase/firestore';
import { fbType, firestoreInit } from '#firebase';

// ----------- import Internals
import { TgetSections } from 'src/sections/types';

export const asyncFb1 = async () => {
  // ---------- set Data
  const refCollection = 'homeSections';

  // ---------- set Async Call
  const refDb = collection(firestoreInit, refCollection)
    // ---------- set Type to FirebaseData
    .withConverter(fbType<TgetSections>());

  const refDoc = doc(refDb, 'qKIIraPamZpXDCDCj72M');
  const dataSuccess = await getDoc(refDoc);

  // ----------- set Return
  return dataSuccess;
};
