import React from 'react';
import { Text, TextStyle, useWindowDimensions } from 'react-native';

import { TextLabel } from 'src/config/theme/TextLabel';
import { AutoView } from 'src/config/theme/AutoView';
import { CallToAction } from '../CallToAction';
import { EachQuestion } from './EachQuestion';
import { TsectionContent } from '../../types';
import { setStl } from 'src/config/theme/setStl';
import { sizeVideo, stlTitle } from 'src/sections/styles';

type Tprops = { content: TsectionContent };

export const EachSection = ({ content }: Tprops) => {
  const { title, imgUrl, questions, sectionID } = content;
  const { width } = useWindowDimensions();

  const loopQuestions = () => {
    const questionArr = Object.values(questions);

    // #TEMP render only the first element
    const item = questionArr[0];

    // return questionArr.map(item => {
    const subtitle = item.subtitle;
    const paragraph = item.paragraph;

    return <EachQuestion questionItem={{ subtitle, paragraph, sectionID }} />;
    // });
  };

  return (
    <AutoView pad_bottom={20} i_center_2 i_space={100}>
      <Title />

      {/* <TextLabel style={stlTitle}>{title['pt-br']}</TextLabel> */}
      <video
        width={sizeVideo(width)}
        height={sizeVideo(width)}
        autoPlay={true}
        loop={true}
        muted={true}
        data-loaded={true}
      >
        <source src={imgUrl} type="video/webm" />
        <source src={imgUrl} type="video/mp4" />
      </video>

      {loopQuestions()}

      <CallToAction sectionID={sectionID} />
    </AutoView>
  );
};

const Title = () => {
  const { width } = useWindowDimensions();

  return (
    <Text style={stlTitle(width)}>
      <Text>{'Crie Aplicativos '}</Text>

      <Text style={{ fontWeight: '300' }}>{'e desenvolva '}</Text>

      <Text>{'SEM LIMITE'}</Text>
    </Text>
  );
};
