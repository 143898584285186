// ----------- import Packs
import React from 'react';
import { InitFunction } from '@morfos/renders';

// ----------- import Internals
import { getItems } from 'src/sections/actions/getItems';
import { UseCondLoader } from '#common/_temp/useMorfos';
import { TextLabel } from 'src/config/theme/TextLabel';
import { AutoView } from 'src/config/theme/AutoView';
import { EachSection } from './EachSection';
import { useData } from '#ctData';

// ----------- set Component
export const LoopSections = () => {
  return (
    <InitFunction setFunction={getItems}>
      <UseCondLoader data={'sections.list.condList'}>
        <LoadedSections />
      </UseCondLoader>
    </InitFunction>
  );
};
export const LoadedSections = () => {
  const arrSections = useData(ct => ct.sections.list.arrSections);
  const noItem = arrSections.length === 0;

  if (noItem)
    return (
      <AutoView fill_2 i_center_2>
        <TextLabel>Nenhuma Section Encontrada!</TextLabel>
      </AutoView>
    );

  const loopSections = () => {
    // #TEMP render only the first element
    const item = arrSections[0];

    // return arrSections.map(item => {
    const { sectionID, sectionModel } = item;
    const { title, imgUrl, questions } = sectionModel;

    return <EachSection content={{ title, imgUrl, questions, sectionID }} />;
    // });
  };

  // ----------- set Return
  return <AutoView i_space={20}>{loopSections()}</AutoView>;
};
