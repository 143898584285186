import React from 'react';
import { Router } from '@morfos/routes';

import { setData } from '#ctData';
import { setRoutes } from './setRoutes';

// ----------- set Connected Router
export const ConnectedRouter = () => {
  // ----------- set Changes
  const getData = (path: string) =>
    setData(() => [{ path: 'dev.selectedRoute', val: path }]);

  // ----------- set Return
  return <Router setHome="home" routes={setRoutes()} getData={getData} />;
};
