// ----------- import packs
import { setConnect } from '@morfos/central-data';

// ----------- import internals
import { routes as sections } from '../../sections/centralData';
import { projectData } from './projectData';
import { callSideR } from './callSideR';
import { devData } from './devData';

// ----------- set Central Data Object
const initCtData = { dev: devData, projectData, sections };
export type TctData = typeof initCtData;

// ----------- set Central Data Connect
export const { Connect, setData, useData, getCtData } = setConnect(initCtData);
export const setSideR = callSideR;
