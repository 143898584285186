// ----------- import Packs
import React from 'react';

// ----------- import Internals
import { Connect } from '#ctData';
import { ConnectedRouter } from './config/routes/ConnectedRouter';
import { SetFonts } from '#common/theme_1/fonts';
import { InitFunction } from '@morfos/renders';
import { initAnalytics } from './config/analytics/index';

// ----------- set Component
export default () => {
  return (
    <Connect>
      <InitFunction setFunction={initAnalytics} />
      <SetFonts>
        <ConnectedRouter />
      </SetFonts>
    </Connect>
  );
};
