// ----------- import Internals
import { setDataBeforeAsync } from './setDataBeforeAsync';
import { setDataSuccess } from './setDataSuccess';
import { Tfield } from 'src/sections/types';
import { setError } from './setError';
import { asyncFb1 } from './asyncFb1';

// ----------- set Main Function
export const setValue = async (currSection: Tfield, priceToPay: number) => {
  setDataBeforeAsync();

  await asyncFb1(currSection, priceToPay).catch(setError);

  setDataSuccess(priceToPay);
};
