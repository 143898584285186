// ----------- import Packs
import React from 'react';
import { Image, useWindowDimensions } from 'react-native';
import { InitFunction } from '@morfos/renders';

// ----------- import Internals
import { LoopSections } from './Components/LoopSections';
import { UseCondLoader } from '#common/_temp/useMorfos';
import { AutoView } from 'src/config/theme/AutoView';
import { A_Welcome } from './Components/A_Welcome';
import { setStl } from 'src/config/theme/setStl';
import { Figma_1 } from './Components/Figma_1';
import { getItems } from './actions/getItems';

// ----------- import Assets
import src1 from '#images/arrows.png';
import { stlContainer, stlOutroTeste } from './styles';
import { Section4 } from './Components/Section4';

// ----------- set Screen Component
export const Sections = () => {
  const { width } = useWindowDimensions();

  // ----------- set Return
  return (
    // <Public>
    <AutoView bgColor="black" fill_2 i_center_h>
      <InitFunction setFunction={getItems}>
        <UseCondLoader data={'sections.list.condList'}>
          <AutoView i_center_2 fill_2 style={stlContainer(width)} i_space={200}>
            <A_Welcome />

            {/* <AutoView style={stlOutroTeste(width)} height={100} /> */}

            <LoopSections />

            {/* <Image source={src1} style={stlImg2(width)} /> */}

            <Figma_1 />

            <Section4 />
          </AutoView>
        </UseCondLoader>
      </InitFunction>
    </AutoView>
  );
};

const stlImg2 = setStl({
  fullBase: {
    width: 20,
    height: 20,
    resizeMode: 'center',
  },
});
